<template>
  <section id="dashboard">
    <b-row class="match-height">
      <b-col
        xl="4"
        md="6"
      >
        <b-card>
          <b-col>
            <h1 class="text-welcome-header">
              Dashboard
            </h1>
            <h3
              v-if="!isSuperuser"
              class="text-welcome-subheader"
            >
              {{ roles }}
            </h3>
            <h3 v-else>
              {{ $t('Superusuario') }}
            </h3>
          </b-col>
        </b-card>
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <data-statistics />
      </b-col>
    </b-row>
    <b-row v-if="isSuperuser || checkPermissions('contents.view_content')">
      <b-col>
        <b-row class="mb-5">
          <b-col>
            <h1>{{ $t('download') }}</h1>
            <h5 class="text-primary">
              {{ $t('downloadContent') }}
            </h5>
          </b-col>
        </b-row>

        <keep-alive>
          <component
            :is="'Download'"
            :key="$route.fullPath"
          />
        </keep-alive>
      </b-col>
    </b-row>
  </section>
</template>

<script>

import { getUserData } from '@/auth/utils'
import { checkPermissions } from '@/store/functions'
import { BRow, BCol, BCard } from 'bootstrap-vue'

const DataStatistics = () => import('../DataStatistics.vue')
const Download = () => import('../../../contents/download/download.vue')
export default {
  components: {
    BRow,
    BCol,
    DataStatistics,
    BCard,
    Download,
  },
  data() {
    return {
      checkPermissions,
      roles: null,
      data: {},
      isSuperuser: false, // getUserData().isSuperuser,
    }
  },
  mounted() {
    this.roles = getUserData().groups.edges.map(group => this.$t(group.node.name)).join(', ')
  },

  created() {

  },

}

</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
